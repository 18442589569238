<template>
  <div>
    <Message
        :message="message"
        @closeAlert="message.show = false"
    ></Message>
    <v-navigation-drawer
        v-model="drawer"
        app
        fixed
        ontransitionend="closeDrawer()"
        disable-route-watcher
        :id="getNavDrawerId()"
        class="pa-6 fadeIn"
    >

      <v-list-item
          class="px-2 py-5 fadeIn">
        <v-list-item-title
            class="text-capitalize"
            align="center">

            <v-img
                v-if="profilePic"
                :src="profilePic"
                max width="60%"
                class="d-inline-flex mb-3"
            />
          <h3
              class="mt-2 mb-2">
            {{user.firstname}} {{ user.lastname }}
          </h3>
          <p>{{ user.companyName }}</p>
        </v-list-item-title>
      </v-list-item>
      <v-list
          nav
          dense
          class="fadeIn">
        <v-list-item-group
            v-model="selectedItem"
            color="primary">
          <v-list-item
              :id="'navD'+item.text"
              v-for="(item, i) in items"
              :key="i"
              :to="item.route">
            <v-list-item-icon>
              <v-icon
                  v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                  v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <template
          v-slot:append>
        <div class="pa-2">
          <v-card align="center" class="pa-3" v-if="user.paymentPlan===0">
            <v-img :src="require('@/assets/images/drawSVGs/premium.svg')" height="80" width="80"></v-img>
            <h4>Jetzt upgraden!</h4>
            <p >Deine Testversion {{ getDays( user.created)  }}.</p>
            <v-card color="primary" dark class="mt-5">
              <v-list-item @click="$router.push('/testphase-abgelaufen')">
                <v-list-item-content>
                  <v-list-item-title>
                    Upgraden
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-card>
        </div>
      </template>
      <v-dialog
          v-model="plansDialog"
          max-width="90%"
      >
        <v-card class="pa-16">
          <v-card-title class="text-h5 justify-center pb-8" id="title">
            <h1 class="text-h4">Wähle deinen Plan</h1>
          </v-card-title>
          <v-card-text>
            <Plans></Plans>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="grey"
                text
                @click="plansDialog = false"
            >
              Abbrechen
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-navigation-drawer>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {error, msgObj, success} from "@/helper/helper";
const Plans = () => import('@/components/generalUI/Plans')
const Message = () => import('@/components/generalUI/Message')

export default {
  name: "NavigationDrawer",
  components: {
    Plans,
    Message
  },
  props: {
    drawer: {
      type: Boolean,
      default() {
        return true
      }
    },
    permanent:{
      type: Boolean,
      default() {
        return false
      }
    }
  },
  computed: {
    showDrawer(){
      return this.drawer
    },
    ...mapGetters('auth', {
      user: 'user',
    }),
    ...mapGetters('file', {
      profilePic: 'profilePic',
    }),

  },
  data: () => ({
    message: msgObj(),
    bugDialog:false,
    dialogContact: false,
    plansDialog: false,
    selectedItem: 0,
    items: [
      {icon: 'mdi-view-dashboard', text: 'Dashboard', route: '/planner/dashboard'},
      {icon: 'mdi-format-list-checks', text: 'Todo-Liste', route: '/planner/todo'},
      {icon: 'mdi-calendar', text: 'Termine', route: '/calendar'},
      {icon: 'mdi-ring', text: 'Hochzeiten', route: '/weddings'},
      {icon: 'mdi-account', text: 'Kunden', route: '/kunden'},
      {icon: 'mdi mdi-handshake', text: 'Dienstleister', route: '/dienstleister'},
      {icon: 'mdi mdi-map-marker-radius-outline', text: 'Locations', route: '/locations'},
      {icon: 'mdi mdi-file-document', text: 'Rechnungen', route: '/rechnungen'},
      {icon: 'mdi mdi-inbox-full', text: 'E-Mails', route: '/email'},

    ]
  }),
  mounted() {
    this.$store.dispatch('file/getProfilePic').catch((err)=>{
      this.message = error(err)
    })
  },
  methods: {
    getNavDrawerId(){
      if(window.innerWidth >= 1264){
        return 'navDrawer'
      }else{
        return ''
      }
    },
    getDays(date){
      let number = 14 - Math.ceil((Date.now()-date) / (1000 * 3600 * 24));
      if(number === 1){
        return 'läuft in einem Tag ab'
      }else if(number === 0){
        return 'läuft heute ab'
      }else if(number < 0){
        return 'ist abgelaufen'
      }
      else{
        return 'läuft in '+number + ' Tagen ab'
      }
    },
    closeDrawer(){
      this.$emit('closeDrawer')
    },
    createBugReport(newValues){
      let bugReport = this.getBugReportFromInput(newValues)
      this.$store.dispatch('mail/createBugReport', {
        bugReport,
        uid: this.user.id
      }).then(() => {
            this.bugDialog =false
            this.message = success('Erfolgreich versendet.')
          }
      ).catch((err)=>{
        this.message = error(err)
        setTimeout(() => this.message.show = false, 5000);
      })

    },
    getBugReportFromInput(newValues) {
      let bugReport = {
        where: '',
        what: '',
        how: '',
        uid: this.user.id,
        bug: false,
        optimizing: false,
      }
      for (let value of newValues) {
        switch (value.title) {
          case 'Fehler':
            bugReport.bug = value.value
            break;
          case 'Verbesserungsvorschlag':
            bugReport.optimizing = value.value
            break;
          case 'Wo aufgetreten?':
            bugReport.where = value.value
            break;
          case 'Fehlermeldung (bei Fehler)':
            bugReport.what = value.value
            break;
          case 'Bitte beschreibe den Ablauf so genau wie möglich':
            bugReport.how = value.value
            break;
        }
      }
      return bugReport
    },
    openPlans(){
      this.plansDialog = true
    },
  }
}
</script>

<style scoped>

</style>
